<template>
    <div>
        <AddNewUser
            v-if="checkUserEdit"
            :addUserHeaderTitle="addUserHeaderTitle"
            :addUserHeaderSupportingText="addUserHeaderSupportingText"
            :userRoles="userRoles"
            :dropdownUniverseList="universes"
            :dropdownDataSetList="datasets"
            :isDataAPISelected="isDataAPISelected"
            :getEditStatus="getEditStatus"
            :editUserData="editUserData"
            :selectedRoles="selectedRoles"
            :disableInviteLink="disableInviteLink"
            :onClickDisableSave="onClickDisableSave"
            @sendInvite="sendInvite"
            @selectedUserRoles="selectedUserRoles"
            @addUser="addUser"
            @switchEditStatus="switchEditStatus"
            @updateUser="updateUser"
            :key="userUpdateKey"
        ></AddNewUser>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import store from "@/store";
import AddNewUser from "../components/AddNewUser.vue";
import { USER_ACTIONS } from "@/store/modules/userManagement/constants.js";
import get from "lodash/get";
import { mapGetters, mapActions, mapState } from "vuex";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";
export default {
    name: "AddNewUserContainer",
    components: {
        AddNewUser,
        LoaderComponent,
        NotificationComponent,
    },

    beforeCreate() {
        this.$store = store;
    },

    data() {
        return {
            showLoader: true,
            addUserHeaderTitle: "Add New User",
            addUserHeaderSupportingText:
                "Add a new Morningstar Auth0 user and set roles",
            userRoles: [],
            universes: [],
            datasets: [],
            disableInviteLink: true,
            isDataAPISelected: false,
            selectedRoles: [],
            getEditStatus: [
                {
                    id: 1,
                    lable: "Enabled",
                },
                {
                    id: 2,
                    lable: "Disabled",
                },
            ],
            userId: "",
            isEnable: true,
            editUserData: {},
            userUpdateKey: 0,
            email: "",
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            onClickDisableSave: true,
        };
    },

    props: {},

    async created() {
        this.fetchUserRoles();
        await this.commonDatasetFunction();
        await this.commonUniverseFunction();
        const userId = await this.$route.query.userId;
        if (userId != undefined) {
            await this.$store.dispatch(USER_ACTIONS.FETCH_EDIT_USER, {
                userId,
            });
            this.editUserData = this.userEdit;
            this.addUserHeaderTitle = "Edit User";
            this.userId = this.userEdit.userId;
            this.isEnable = this.userEdit.enabled;
            this.email = this.userEdit.email;
            this.disableInviteLink = !this.userEdit.sendInviteOrNot;
            let role = this.userEdit.roles;
            this.userRoles.forEach((item) => {
                role.forEach((element) => {
                    if (item.key === element.roleId) {
                        item.isSelected = true;
                        this.selectedRoles.push(item.key);
                    }
                });
            });
            this.commondropdownFunction();
        }
        this.showLoader = false;
        this.userUpdateKey += 1;
    },

    computed: {
        ...mapGetters("reporting", ["getDataSets", "getUniverses"]),
        ...mapState({
            userRolesList(state) {
                return get(state, "userManagement.userRoles.roles", []);
            },
            userEdit(state) {
                return get(state, "userManagement.editUsers.editUsers", []);
            },
            userSave(state) {
                return get(state, "userManagement.saveUsers.saveUsers", []);
            },
            userSaveError(state) {
                return get(state, "userManagement.saveUsers.__error__", null);
            },
            mailInviteError(state) {
                return get(state, "userManagement.sendMailInvite.__error__", null);
            },
            userUpdate(state) {
                return get(state, "userManagement.updateUsers.updateUsers", []);
            },
            userUpdateError(state) {
                return get(state, "userManagement.updateUsers.__error__", null);
            },
        }),
        checkUserEdit() {
            if (this.$route.query.userId) {
                return Object.keys(this.editUserData).length;
            } else {
                return true;
            }
        },
    },

    methods: {
        ...mapActions("reporting", ["fetchDataSetsList", "fetchUniversesList"]),
        async sendInvite(email) {
            await this.$store.dispatch(USER_ACTIONS.SEND_MAIL_INVITE, {email});
            let response = !this.mailInviteError;
            const obj = {
                response,
                ntfnMsg: "Mail invite sent, please check your email!",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.mailInviteError,
            };
            this.notificationResponse(obj);
        },
        // this updates as per the roles selected
        async selectedUserRoles(event, role) {
            if (event === true) {
                role.isSelected = true;
                this.selectedRoles.push(role.key);
                this.commondropdownFunction();
            } else if (event === false) {
                role.isSelected = false;
                this.selectedRoles.forEach((item, index) => {
                    if (item === role.key) {
                        this.selectedRoles.splice(index, 1);
                    }
                });
                this.commondropdownFunction();
            }
        },

        commondropdownFunction() {
            if (
                this.selectedRoles.includes(
                    "1bf4ca73-5d3d-40c1-b854-9db960a3cfcd"
                )
            ) {
                this.isDataAPISelected = true;
            } else {
                this.isDataAPISelected = false;
            }
        },

        // fetch roles from backend
        async fetchUserRoles() {
            await this.$store.dispatch(USER_ACTIONS.FETCH_USER_ROLES);
            this.userRolesList.forEach((item) => {
                item.isSelected = false;
                this.userRoles.push(item);
            });
        },

        // event for data-set dropdown
        async commonDatasetFunction() {
            var obj = {
                Page: 1,
                pageSize: 0,
            };
            await this.fetchDataSetsList(obj);
            let response = this.getDataSets.dataset;
            if(response != undefined){
            response.forEach((item) => {
                let data = {};
                data.text = item.datasetname;
                data.value = item.datasetid.toString();
                this.datasets.push(data);
            });
            }
        },

        // ebent for universe dropdown
        async commonUniverseFunction() {
            var obj = {
                page: 1,
                pageSize: 0,
            };
            await this.fetchUniversesList(obj);
            let response = this.getUniverses.universelist;
            if(response != undefined){
            response.forEach((item) => {
                let data = {};
                data.text = item.listdescription;
                data.value = item.listid.toString();
                this.universes.push(data);
            });
            }
        },

        // add user
        async addUser(selectedUniversesValue, selectedDataSetsValue, email) {
            let data = {};
            data.userId = "";
            data.email = email;
            data.groups = this.selectedRoles;
            data.enabled = this.isEnable;
            data.universeIds = selectedUniversesValue;
            data.datasetIds = selectedDataSetsValue;
            await this.$store.dispatch(USER_ACTIONS.FETCH_SAVE_USER, {
                data,
            });
            let response = this.userSave;
            const obj = {
                response,
                ntfnMsg: "The new user has been added.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.userSaveError,
            };
            this.notificationResponse(obj);
        },

        // update user
        async updateUser(selectedUniversesValue, selectedDataSetsValue) {
            let data = {};
            data.userId = this.userId;
            data.email = this.email;
            data.groups = this.selectedRoles;
            data.enabled = this.isEnable;
            data.universeIds = selectedUniversesValue;
            data.datasetIds = selectedDataSetsValue;
            await this.$store.dispatch(USER_ACTIONS.FETCH_UPDATE_USER, {
                data,
            });
            let response = this.userUpdate;
            const obj = {
                response,
                ntfnMsg: "The user has been updated.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.userUpdateError,
            };
            this.notificationResponse(obj);
        },

        // switch radio button
        switchEditStatus(EditStatus) {
            if (EditStatus.lable == "Enabled") {
                this.isEnable = true;
            } else {
                this.isEnable = false;
            }
        },

        // sleep(timeout) {
        //     return new Promise((resolve) => setTimeout(resolve, timeout));
        // },

        // event for common notification
        async notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response == true) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
                // await this.sleep(this.getRouterDetail(),2000);
                this.onClickDisableSave = false;
                setTimeout(() => {
                    this.$router.push({
                        name: "user management",
                    });
                }, 2000);
            } else if (ntfnfailureMsg === "No changes were made.") {
                this.showLoader = false;
                this.notificationKey = "informational-example";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "informational";
                this.showNotification = true;
                this.onClickDisableSave = true;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
                this.onClickDisableSave = true;
            }
        },
    },
};
</script>
