<template>
    <div id="addNewUser">
        <div class="add_user">
            <div class="upperSection">
                <mds-layout-grid class="add_user">
                    <mds-row>
                        <span>
                            <router-link
                                :to="{
                                    name: getRouteDetails(
                                        '/settings/user-management'
                                    ),
                                }"
                            >
                                List Users
                            </router-link>
                        </span>
                        <span class="add_user_router">
                            / {{ addUserHeaderTitle }}
                        </span>
                    </mds-row>
                    <mds-row class="add_user_heading">
                        {{ addUserHeaderTitle }}
                    </mds-row>
                    <mds-row>
                        <p>
                            <mds-button
                                :disabled="disableInviteLink"
                                @click="sendInvite"
                                variation="flat">
                                    Send User Invite 
                                </mds-button>
                        </p>
                    </mds-row>
                    <mds-row
                        v-if="!Object.keys(this.editUserData).length"
                        class="add_user-supporting_text"
                    >
                        <span>{{ addUserHeaderSupportingText }}</span>
                    </mds-row>
                </mds-layout-grid>

                <mds-layout-grid class="add_user-email">
                    <mds-row>
                        <mds-col :cols="6">
                            <mds-row>
                                <mds-col :cols="8">
                                    <mds-form>
                                        <mds-fieldset>
                                            <mds-input
                                                maxlength="250"
                                                label="Email Address"
                                                placeholder="Input Email"
                                                @focus="
                                                    triggerInputField('email')
                                                "
                                                @keyup="debounceInput"
                                                :error="email.error"
                                                :error-text="[email.errorMsg]"
                                                v-model.trim="email.data"
                                                :disabled="userInputField"
                                            ></mds-input>
                                        </mds-fieldset>
                                    </mds-form>
                                </mds-col>
                            </mds-row>

                            <!-- edit Account Creation Date -->
                            <mds-row
                                v-if="Object.keys(this.editUserData).length"
                            >
                                <mds-col>
                                    <div class="account_creation_heading">
                                        Account Creation Date
                                    </div>
                                    <div>{{ createdDate }}</div>
                                </mds-col>
                            </mds-row>

                            <mds-row>
                                <mds-form class="add_user-roles">
                                    <div class="roles_heading">Role</div>
                                    <mds-fieldset
                                        class="add_user_roles_checkbox"
                                    >
                                        <mds-checkbox
                                            name="roles"
                                            v-for="(
                                                role, index
                                            ) in userRoleList"
                                            :key="index"
                                            :checked="role.isSelected"
                                            :value="role.value"
                                            :label="role.value"
                                            @change="
                                                selectedUserRoles($event, role)
                                            "
                                        ></mds-checkbox>
                                    </mds-fieldset>
                                </mds-form>
                            </mds-row>

                            <!-- edit status radio button -->
                            <mds-row
                                v-if="Object.keys(this.editUserData).length"
                                class="status_radio_button_section"
                            >
                                <mds-form>
                                    <mds-fieldset
                                        variation="radio-group"
                                        legend="Status"
                                    >
                                        <mds-radio-button
                                            class="radioButtonGroup"
                                            name="group"
                                            v-for="EditStatus in getEditStatus"
                                            :key="EditStatus.id"
                                            v-model="switchEditStatus"
                                            :value="EditStatus.id"
                                            :label="EditStatus.lable"
                                            @input="
                                                switchedEditStatus(EditStatus)
                                            "
                                        ></mds-radio-button>
                                    </mds-fieldset>
                                </mds-form>
                            </mds-row>
                        </mds-col>

                        <mds-col :cols="6" v-if="isDataAPISelected">
                            <!-- <div class="add_user-data_api_border"></div> -->
                            <mds-row
                                ><div class="add_user-data_api_header">
                                    Data API Access
                                </div></mds-row
                            >
                            <!-- combo box for universes & data sets -->
                            <mds-row>
                                <mds-col :cols="10">
                                    <mds-form
                                        class="
                                            add_user-data_api_universes_combobox
                                        "
                                    >
                                        <mds-combo-box
                                            :key="domicileUniverseKey"
                                            placeholder="Select text"
                                            label="Universes"
                                            v-model="selectedUniversesValue"
                                            multiple
                                            :multipleItemLimit="2"
                                            :data-set="dropdownUniverseList"
                                        ></mds-combo-box>
                                    </mds-form>
                                </mds-col> </mds-row
                            ><mds-row>
                                <mds-col :cols="10">
                                    <mds-form
                                        class="
                                            add_user-data_api_dataSet_combobox
                                        "
                                    >
                                        <mds-combo-box
                                            :key="domicileDataSetKey"
                                            placeholder="Select text"
                                            label="Data Sets"
                                            v-model="selectedDataSetsValue"
                                            multiple
                                            :multipleItemLimit="2"
                                            :data-set="dropdownDataSetList"
                                        ></mds-combo-box>
                                    </mds-form>
                                </mds-col>
                            </mds-row>
                        </mds-col>
                    </mds-row>
                </mds-layout-grid>
            </div>
            <div>
                <!-- footer section -->
                <common-footer
                    :buttonName="SaveButtonName"
                    @cancelButton="cancelButton"
                    @saveButton="saveButton"
                    :isDisabled="!saveButtonDisable"
                >
                </common-footer>
            </div>
        </div>

        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import debounce from "lodash/debounce";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import orderBy from "lodash/orderBy";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import { MdsButton } from '@mds/button'
import MdsFieldset from "@mds/fieldset";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import CommonFooter from "../../data-dissemination/components/commonFooter.vue";
import MdsCheckbox from "@mds/checkbox";
import MdsComboBox from "@mds/combo-box";
import { validateSingleEmail } from "@/helpers/validator.js";
import MdsRadioButton from "@mds/radio-button";
import { dateFormatterMixin } from "../../../../mixins/date.js";

export default {
    name: "AddNewUser",
    components: {
        MdsButton,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        MdsInput,
        MdsFieldset,
        MdsCheckbox,
        CommonFooter,
        MdsComboBox,
        LoaderComponent,
        MdsRadioButton,
    },
    data() {
        return {
            showLoader: true,
            email: {
                data: "",
                error: false,
                errorMsg: "Enter valid email with max length 60 characters",
            },
            selectedUniversesValue: [],
            selectedDataSetsValue: [],
            domicileDataSetKey: 0,
            domicileUniverseKey: 0,
            isValidEmail: false,
            switchEditStatus: 0,
            userInputField: false,
            createdDate: "",
            SaveButtonName: "Add User",
        };
    },

    mixins: [dateFormatterMixin],
    props: {
        addUserHeaderTitle: {
            type: String,
            default: "",
        },
        addUserHeaderSupportingText: {
            type: String,
            default: "",
        },
        userRoles: {
            type: Array,
            default: () => [],
        },
        isDataAPISelected: {
            type: Boolean,
            default: false,
        },
        disableInviteLink: {
            type: Boolean,
            default: false,
        },
        dropdownUniverseList: {
            type: Array,
            default: () => [],
        },
        dropdownDataSetList: {
            type: Array,
            default: () => [],
        },
        getEditStatus: {
            type: Array,
            default: () => [],
        },
        editUserData: {
            type: Object,
            default: () => {},
        },
        selectedRoles: {
            type: Array,
            default: () => [],
        },
        onClickDisableSave: {
            type: Boolean,
            default: false,
        },
    },

    async created() {
        if (Object.keys(this.editUserData).length) {
            this.showLoader = true;
            if (this.editUserData.enabled == true) {
                this.switchEditStatus = 1;
            } else {
                this.switchEditStatus = 2;
            }
            this.email.data = this.editUserData.email;
            this.userInputField = true;
            this.createdDate = this.convertDate(this.editUserData.createdTime);
            this.checkValidEmail(this.editUserData.email);
            if (
                this.editUserData.datasetids !== null &&
                this.editUserData.universeids !== null
            ) {
                let datasetid = this.editUserData.datasetids;

                for (let i = 0; i < datasetid.length; i++) {
                    this.selectedDataSetsValue[i] = datasetid[i].toString();
                }

                let universeid = this.editUserData.universeids;

                for (let i = 0; i < universeid.length; i++) {
                    this.selectedUniversesValue[i] = universeid[i].toString();
                }
                this.domicileUniverseKey += 1;
                this.domicileDataSetKey += 1;
            }
            this.SaveButtonName = "Update User";
        }
        this.showLoader = false;
    },

    computed: {
        saveButtonDisable() {
            if (this.isDataAPISelected == true) {
                return (
                    this.selectedUniversesValue.length &&
                    this.selectedDataSetsValue.length &&
                    this.isValidEmail &&
                    this.selectedRoles.length &&
                    this.onClickDisableSave
                );
            } else {
                return (
                    this.isValidEmail &&
                    this.selectedRoles.length &&
                    this.onClickDisableSave
                );
            }
        },
        userRoleList() {
            return orderBy(this.userRoles, "value");
        },
    },

    methods: {
        sendInvite() {
            this.$emit('sendInvite',this.editUserData.email)
        },
        debounceInput: debounce(function (e) {
            this.checkValidEmail(e.target.value);
        }, 500),

        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },

        // cancel button in footer
        cancelButton() {
            this.toggleCancelButton = !this.toggleCancelButton;
            this.$router.push("/settings/user-management");
        },
        saveButton() {
            this.onClickDisableSave = false;
            if (Object.keys(this.editUserData).length) {
                this.$emit(
                    "updateUser",
                    this.selectedUniversesValue,
                    this.selectedDataSetsValue
                );
            } else {
                this.$emit(
                    "addUser",
                    this.selectedUniversesValue,
                    this.selectedDataSetsValue,
                    this.email.data
                );
            }
        },

        selectedUserRoles(event, role) {
            if (role.value === "Data API") {
                this.selectedUniversesValue = [];
                this.selectedDataSetsValue = [];
            }
            this.$emit("selectedUserRoles", event, role);
        },

        triggerInputField(param) {
            if (param === "email") {
                this.$data.email.error = false;
            }
        },

        checkValidEmail(email) {
            
            if (email && !validateSingleEmail(email)) {
                this.$data.email.error = true;
                this.isValidEmail = false;
                return;
            } else if (email && validateSingleEmail(email)) {
                this.$data.email.error = false;
                this.isValidEmail = true;
            } else if (email.length == 0) {
                this.$data.email.error = false;
                this.isValidEmail = false;
            }
        },

        // switch radio button
        switchedEditStatus(EditStatus) {
            this.$emit("switchEditStatus", EditStatus);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.add_user {
    padding: 8px 16px 0 16px;
}
.add_user_heading {
    margin-top: $mds-space-2-x;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
    color: #1e1e1e;
}

.add_user-supporting_text {
    margin-bottom: $mds-space-1-x;
    height: 36px;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 18px;
    line-height: 36px;
    color: #1e1e1e;
}

.add_user_router {
    margin-left: $mds-space-half-x;
}

.add_user-roles {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-1-x;
    margin-left: $mds-space-2-x;
    font-size: 16px;
    font-weight: bold;
    width: 400px;
}

.roles_heading {
    margin-bottom: $mds-space-1-x;
    font-size: 16px;
}

.add_user_roles_checkbox {
    height: 200px;
    overflow-y: auto;
}

.add_user-comboBox {
    margin-top: $mds-space-2-x;
    width: 500px;
}

.add_user-data_api {
    margin-top: $mds-space-1-x;
    height: 450px;
}
.add_user-data_api_border {
    border-bottom: 1px solid #cccccc;
}

.add_user-data_api_header {
    margin-left: $mds-space-2-x;
    margin-top: $mds-space-1-x;
    margin-bottom: $mds-space-4-x;
    font-weight: bold;
    font-size: 16px;
}
.add_user-data_api_dataSet_combobox {
    margin-top: $mds-space-4-x;
}

.create_alert_section_Middle_section_border {
    margin: 8px 8px 0 8px;
    border-bottom: 1px solid #cccccc;
}

.account_creation_heading {
    font-size: 16px;
    line-height: 21px;
    color: #1e1e1e;
    font-weight: bold;
    margin-top: $mds-space-3-x;
    margin-bottom: $mds-space-1-x;
}

.status_radio_button_section {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-1-x;
    margin-left: $mds-space-quarter-x;
}

.upperSection {
    min-height: 80vh;
}

.add_user-email {
    margin-top: $mds-space-1-x;
}
</style>