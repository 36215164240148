export function validateText(value) {
    return value && value.trim && value.trim().length && value.trim().length < 250;
}

export function validateMultipleEmail(value) {
    if (value.length > 250) return false;
    // eslint-disable-next-line
    const emailRegex = /^[a-z0-9]+([\.\-\_a-z0-9]+)*@[a-z0-9]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;
    var emails = value.replace(/\s/g, "").split(/,|;/); 
   
    for(let i = 0; i < emails.length; i++) {
        if(!emailRegex.test(emails[i])) {
            return false;
        }
    }       
    return true;
}

export function validateSingleEmail(value) {
    if (value.length > 60) return false;
    // eslint-disable-next-line
    const emailRegex = /^[a-z0-9]+([\.\-\_a-z0-9]+)*@[a-z0-9]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;

    return value && emailRegex.test(value);
}

export function removeDuplicateElements(data) {
    return Array.from(new Set(data.map(item => item.templateid)))
        .map(val => data.find(item => item.templateid === val));
}