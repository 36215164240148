<template>
  <div class="footerSection">
    <mds-layout-grid>
      <mds-row>
        <mds-col>
          <div class="borderLine"></div>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col>
          <mds-button type="button" variation="secondary" @click="cancelButton">
              Cancel
          </mds-button>
        </mds-col>
        <mds-col class="saveButton">
          <span>
            <mds-button 
              @click="saveButton"
              variation="primary"
              :disabled="isDisabled"
            >
            {{buttonName}}
            </mds-button >
          </span>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </div>
</template>

<script>
import { MdsButton } from "@mds/button";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
export default {
  name: "common-footer",

  components: {
    MdsButton,
    MdsLayoutGrid, 
    MdsRow, 
    MdsCol
  },

  props: {
    buttonName: {
      type: String,
      required: true,
    },

    isDisabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    cancelButton() {
      this.$emit("cancelButton");
    },
    saveButton() {
      this.$emit("saveButton");
    },
  },

};
</script>

<style lang="scss">
@import "../../../../assets/css/styles.scss";
.footerSection {
  margin-top: 10px;
  margin-bottom: 10px;
}

.borderLine{
    border-top: 1px solid $mds-color-neutral-80;
    margin-bottom: 16px;
}

.saveButton{
    text-align:right
}
</style>
